export const STACK_GRAPH_MONTHS_LIMIT = 10;
export const GRAPH_MONTH_FORMAT = 'YYYY-MM';
export const GRAPH_WEEK_FORMAT = 'GGGG-[W]WW';
export const GRAPH_DAY_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_UNIT_FORMAT = 'DD MMM';
export const OVERVIEW_CHART_LIMIT = 8;
export const OVERVIEW_STATUS_LINK = 'http://status.orbs.network/';
export const LOCAIZE_PROJECT_ID = '5291725d-33fb-442f-9586-fcd6d817ecfc';
export const LOCAIZE_API_KEY = 'bc445bc2-fd8a-450d-a5e1-b9807a7a8a56';
export const LOCAIZE_API = 'https://api.locize.app';

export const overviewguardiansColors = [
    '#00A7EA',
    '#74DABF',
    '#FF8FC8',
    '#FF4463',
    '#FFBD51',
    '#74737A',
    '#00D48C',
    '#CF4E81',
    '#FFEC2A',
    '#B9770B',
    '#00187C',
    '#FBCBFF',
    '#6BBA70'
];
